import React, { Suspense, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

const Loading = React.lazy(() => import("./pages/Loading/Loading"));
const Error = React.lazy(() => import("./pages/Error/Error"));
const SharedLayout = React.lazy(() =>
  import("./pages/SharedLayout/SharedLayout")
);
const Verify = React.lazy(() => import("./pages/Verify/Verify"));

const Home = React.lazy(() => import("./pages/Home/Home"));
const EBrochure = React.lazy(() => import("./pages/EBrochure/EBrochure"));
const Tour = React.lazy(() => import("./pages/Tour/Tour"));
const Plan = React.lazy(() => import("./pages/Plan/Plan"));
const House = React.lazy(() => import("./pages/House/House"));
const Utils = React.lazy(() => import("./pages/Utilities/Utilities"));
const Location = React.lazy(() => import("./pages/Location/Location"));
const Gallery = React.lazy(() => import("./pages/Gallery/Gallery"));
const Policy = React.lazy(() => import("./pages/Policy/Policy"));

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;
  const [checkVerify, setCheckVerify] = useState(true);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            {checkVerify === true ? (
              <Routes>
                <Route path="/" index element={<Loading />} />
                <Route element={<SharedLayout />}>
                  <Route index path="/home" element={<Home />} />
                  <Route index path="/ebrochure" element={<EBrochure />} />
                  <Route index path="/tour" element={<Tour />} />
                  <Route index path="/plan" element={<Plan />} />
                  <Route index path="/house" element={<House />} />
                  <Route index path="/utils" element={<Utils />} />
                  <Route index path="/location" element={<Location />} />
                  <Route index path="/gallery" element={<Gallery />} />
                  <Route
                    index
                    path="/chinh-sach-bao-mat"
                    element={<Policy />}
                  />
                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={<Verify setCheckVerify={setCheckVerify} />}
                />
              </Routes>
            )}
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
