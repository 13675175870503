import { createSlice } from "@reduxjs/toolkit";
// import home_project from "../../assets/images/home_project.jpg";
import home_project_ecoriver from "../../assets/images/home_project_ecoriver.jpg";
import home_project_ecovillage from "../../assets/images/home_project_ecovillage.jpg";
import home_greencity from "../../assets/images/home_greencity.jpg";
import home_project_ecocentral from "../../assets/images/home_project_ecocentral.jpg";

import home_project_overview from "../../assets/videos/home_project_overview.mp4";
import home_project_overview_poster from "../../assets/images/home_project_overview_poster.jpg";

// 2014
import rw_dax from "../../assets/images/rw_dax.png";
// 2015
import rw_kdt from "../../assets/images/rw_kdt.png";
// 2020
import rw_kdt2 from "../../assets/images/rw_kdt2.png";
import rw_ddt from "../../assets/images/rw_ddt.png";
import rw_daph from "../../assets/images/rw_daph.png";
import rw_dnxd from "../../assets/images/rw_dnxd.png";
// 2022
import rw_dapt from "../../assets/images/rw_dapt.png";
// 2023
import rw_t3 from "../../assets/images/rw_t3.png";

const initialState = {
  homeNavActive: 1,
  activeHomeProjectNumber: 1,
  homeNavbarActiveList: [
    {
      id: 1,
      text: "giới thiệu",
    },
    {
      id: 2,
      text: "giải thưởng",
    },
    {
      id: 3,
      text: "dự án",
    },
  ],
  homeIntroduceActiveNumberList: [
    {
      id: 1,
      text: "TỔNG QUAN",
    },
    {
      id: 2,
      text: "NHÀ SÁNG LẬP",
    },
    {
      id: 3,
      text: "THÀNH PHỐ XANH ECOPARK",
    },
  ],
  homeRewardActiveNumberList: [
    {
      id: 1,
      img: rw_kdt,
      year: 2015,
    },
    {
      id: 2,
      img: rw_kdt2,
      year: 2020,
    },
    {
      id: 3,
      img: rw_dapt,
      year: 2022,
    },
    {
      id: 4,
      img: rw_dax,
      year: 2014,
    },
    {
      id: 5,
      img: rw_t3,
      year: 2023,
    },
    {
      id: 6,
      img: rw_ddt,
      year: 2020,
    },
    {
      id: 7,
      img: rw_daph,
      year: 2020,
    },
    {
      id: 8,
      img: rw_dnxd,
      year: 2020,
    },
    
    
  ],
  homeProjectActiveNumberList: [
    {
      id: 1,
      text: "TỔNG QUAN",
      video: home_project_overview,
      poster: home_project_overview_poster,
    },
    {
      id: 2,
      text: "THÀNH PHỐ XANH ECOPARK",
      img: home_greencity,
      information: {
        title: "Thành phố xanh Ecopark",
        location: "Nằm tại phía Đông Nam thành phố Hà Nội",
        scale: "Quy mô 500ha",
        greenDensity: "Mật độ cây xanh: 120 cây/người",
        fanpage: "https://www.facebook.com/ecopark.com.vn",
        youtube: "https://www.youtube.com/@EcoparkOfficial",
      },
    },
    {
      id: 3,
      text: "ECORIVERS",
      img: home_project_ecoriver,
      information: {
        title: "Ecorivers - Biểu tượng sống mới tại Hải Dương",
        location: "Nằm tại thành phố Hải Dương",
        scale: "Quy mô 110ha",
        greenDensity: "Diện tích cây xanh, mặt nước chiếm 20%",
        fanpage: null,
        youtube: null,
      },
    },
    {
      id: 4,
      text: "ECO CENTRAL PARK",
      img: home_project_ecocentral,
      information: {
        title: "Đại công viên xanh Eco Central Park",
        location: "Nằm tại phía Đông Nam, cách trung tâm thành phố Vinh 7km",
        scale: "Quy mô 200ha",
        greenDensity: "Mật độ cây xanh: 100 cây/người",
        fanpage: "https://www.facebook.com/DAICONGVIENXANH",
        youtube: "https://www.youtube.com/@ecocentralparkofficial",
      },
    },
    {
      id: 5,
      text: "ECOVILLAGE SAIGON RIVER",
      img: home_project_ecovillage,
      information: {
        title:
          "EcoVillage Saigon River - vùng đất Bluezones đầu tiên tại Việt Nam",
        location: "Cách nhà thờ Đức Bà 18km",
        scale: "Quy mô 55ha",
        greenDensity: "Mật độ cây xanh: 170 cây/người",
        fanpage: "https://www.facebook.com/DAOHOAECOVILLAGESAIGONRIVER",
        youtube: "https://www.youtube.com/@EcoVillageSaigonRiverofficial",
      },
    },
  ],
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomeNavActive: (state, action) => {
      state.homeNavActive = action.payload;
    },
    setActiveHomeProjectNumber: (state, action) => {
      state.activeHomeProjectNumber = action.payload;
    },
  },
});

export default homeSlice.reducer;
export const { setHomeNavActive, setActiveHomeProjectNumber } =
  homeSlice.actions;
