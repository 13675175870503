import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeNumber: 1,
  activeSubmenuNumber: 0,
  activeSubdivisionNumber: 0,
  tourTitle: "",
  activeHouseSampleView: false,
  activePlanView: false,
  houseSampleViewTitle: "",
  planViewSelectedList: [],
  day: true,
  tourActiveNumberList: [
    {
      id: 1,
      text: "Tổng Quan Ngày",
    },
    {
      id: 2,
      text: "Tổng Quan Đêm",
    },
    // {
    //   id: 1,
    //   text: "Tổng Quan",
    // },
    // {
    //   id: 2,
    //   text: "Phân Khu",
    //   submenu: [
    //     {
    //       id: 1,
    //       submenuText: "RIVERWALK"
    //     },
    //     {
    //       id: 2,
    //       submenuText: "ONSEN"
    //     },
    //   ]
    // },
  ],
  tourSubdivisionActiveNumberList: [
    {
      id: 1,
      text: "BIỆT THỰ ĐƠN LẬP",
    },
    {
      id: 2,
      text: "BIỆT THỰ SONG LẬP",
    },
  ],
  tourFloorPlanActiveNumberList: [
    {
      id: 1,
      text: "Xem Mặt Bằng",
    },
  ],
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setActiveNumber: (state, action) => {
      state.activeNumber = action.payload;
    },
    setActiveSubmenuNumber: (state, action) => {
      state.activeSubmenuNumber = action.payload;
    },
    setActiveSubdivisionNumber: (state, action) => {
      state.activeSubdivisionNumber = action.payload;
    },
    setTourTitle: (state, action) => {
      state.tourTitle = action.payload;
    },
    setActiveHouseSampleView: (state, action) => {
      state.activeHouseSampleView = action.payload;
    },
    setHouseSampleViewTitle: (state, action) => {
      state.houseSampleViewTitle = action.payload;
    },
    setPlanViewSelectedList: (state, action) => {
      state.planViewSelectedList = action.payload;
    },
    setActivePlanView: (state, action) => {
      state.activePlanView = action.payload;
    },
    setDay: (state, action) => {
      state.day = action.payload;
    },
  },
});

export default tourSlice.reducer;
export const {
  setActiveNumber,
  setActiveSubmenuNumber,
  setActiveSubdivisionNumber,
  setTourTitle,
  setActiveHouseSampleView,
  setHouseSampleViewTitle,
  setPlanViewSelectedList,
  setActivePlanView,
  setDay,
} = tourSlice.actions;
